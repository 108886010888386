import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'

import RenderExpandableCell from '../../utils/RenderExpandableCell';
import Spinner from '../../layouts/Spinner'

// MUI
import { TextField, Typography } from '@mui/material';
import { DataGrid, 
    GridToolbarContainer,GridToolbarColumnsButton,
    getGridStringOperators,GridToolbarExport
 } from '@mui/x-data-grid';

 const SearchBar = ({setSearchQuery}) => (
    <form style={{
        marginLeft: 5
      }}>
      <TextField
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"
      />
    </form>
  );

  const CustomToolbar = (props) => (
    <Fragment>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
      <SearchBar {...props} />
    </Fragment>
  );

const prepTableData =  (data) => {
    let returnArray = []
    if(data){
        data.map(c => {
            const id = c.id
            const medication = c.medication ? c.medication[0] : ''
            const note = c.note ? c.note.join(',') : ''
            const reason = c.reason ? c.reason.join(',') : ''
            const instruction = c.dosage?.length > 0 ? c.dosage.map(d => {if(d.instruction) return d.instruction}).join(',') : ''
            const route = c.dosage?.length > 0 ? c.dosage.map(d => {if(d.route) return d.route}).join(',') : ''
            const status = c.status ? c.status : ''
            const startDate = Object.keys(c.startDate).length > 0 ? moment(c.startDate.date).format('YYYY-MM-DD') : ''
            const asserter = c.asserter ? c.asserter : ''
            const dose = c.dosage?.length > 0 ? prepDose(c.dosage) : ''

            returnArray.push({id,medication, note, asserter, startDate, status, reason, instruction, dose, route})
        })
    }

    return returnArray

}

const prepDose = (dosage) =>{
    const returnValue = []
    dosage.map(d=> {
        if(d.dose){
            d.dose.map(x => {
                returnValue.push(`${x.value ? x.value : ''}${x.unit ? x.unit : ''}`)
            })
        }
    })
    return returnValue.join(',')
}


const MedicationsDG = ({medications}) => {
    let medicationData
    if(medications) medicationData = prepTableData(medications)
        
    const [tableData, setTableData] = useState(medicationData);

    useEffect(() => {
        setTableData(medicationData)
    }, [medications])

    //Defines conditions Data Grid columns
    const columnsFormat = [
        {field: 'medication', headerName: 'Medication', width: 400,
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.medication.toUpperCase()}/>
            }},
        {field: 'instruction', headerName: 'Instruction', width: 400,
            renderCell: (params) => {
                // return <RenderExpandableCell value={params.row.instruction.toUpperCase()}/>
                return (
                    <Typography variant="body2">
                            <span >{params.row.instruction.toUpperCase()}</span>;
                        </Typography>
                )
            }},
        {field: 'dose', headerName: 'Dosage', width: 115, 
            renderCell: (params) => {
                return params.row.dose.toUpperCase() 
            }},
        {field: 'route', headerName: 'Route', width: 150, 
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.route.toUpperCase()}/>
            }},
        {field: 'status', headerName: 'Status', width: 115, 
            renderCell: (params) => {
                return params.row.status.toUpperCase() 
            }},
        {field: 'startDate', headerName: 'Start Date', width: 150,
            renderCell: (params) => {
                return params.row.startDate
            }},
        {field: 'reason', headerName: 'Reason', width: 250,
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.reason.toUpperCase()}/>
            }},
        {field: 'asserter', headerName: 'Asserter', width: 175, 
            renderCell: (params) => {
                return params.row.asserter.toUpperCase()
            }},
        {field: 'note', headerName: 'Note', width: 400,
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.note.toUpperCase()}/>
            }},
    ]

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

      const requestSearch = (searchValue) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = medicationData.filter((o) => {
          return Object.keys(o).some((k) => {
            return searchRegex.test(o[k]?.toString());
          });
        });
        setTableData(filteredRows);
      };
    

    if(tableData) {
        
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                                setSearchQuery: (searchVal) => requestSearch(searchVal),
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={tableData}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default MedicationsDG