import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Tooltip,
    Button,
    IconButton
} from "@mui/material"

import Alert from '@mui/material/Alert';
import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp';

// context
import { clearError, PeopleState, deleteAddressAsync } from "./peopleSlice"
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';


function PaperComponent(props) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

function ShowAlert(props) {
    return <Alert elevation={6} variant={'filled'} {...props} />
}

const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}

const DeleteAddress = ({address}) => {

    const { error } = useSelector(PeopleState)
    const dispatch = useDispatch()

    const initialState = {
        open: false,

    }

    const [dialog, setDialog] = useState(initialState)
    const { open} = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = (e) => {
        dispatch(clearError())
        dispatch(deleteAddressAsync(address.id))
        setDialog(initialState)
    }

    return (
        <Fragment>
            <IconButton color='inherit' onClick={handleClickOpen}>
                <Tooltip title='Delete Address'>
                    <RemoveCircleOutlineSharpIcon fontSize='small' style={{color: '#939393'}}/>
                </Tooltip>
            </IconButton>                   
    
            <Dialog open={open} id="deleteAddressModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <DialogTitle id='form-dialog-title'>Delete Address</DialogTitle>
                <DialogContent>
                <Separator />
                    <DialogContentText>
                        { error && <ShowAlert severity={'error'}>{error}</ShowAlert> }
                    </DialogContentText>

                    <DialogContentText>You are about to delete the address: {address.line1}, {address.city}, {address.state}, {address.postalCode} </DialogContentText>
                    <Separator />
                    <DialogContentText>Unit 387 supports historical addresses for improved record retrieval.</DialogContentText>
                    <DialogContentText>You should only delete an address if it was added in error or never associated to the person.</DialogContentText>
                    <DialogContentText>If there is a new current address, please update this address type rather than deleting.</DialogContentText>
                    <Separator />
                    <DialogContentText>Do you with to continue?</DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default DeleteAddress