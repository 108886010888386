import React, { Fragment } from 'react'

//import local component
import PeopleDG from './PeopleDG';

const People = props => {
        return (
            <Fragment>
                <h1>Patient Management</h1>
                <PeopleDG></PeopleDG>
            </Fragment>
        )
}

export default People