import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const slice = createSlice({
    name: 'requests',
    initialState: {
        requests: [],
        loading: true,
        error: null,
        pageSize: 25,
        page: 0,
        requestCount: null,
        maxPage: null,
        previouslyLoaded: null,
        sort: [{field:"createdAt", sort:"desc"}]
    },
    reducers: {
        loadRequests : (state, action) => { 
            state.requests = action.payload.requests
            // state.page = action.payload.page - 1 
            // state.pageSize = action.payload.PAGE_SIZE 
            // state.requestCount = action.payload.totalRequests
            // state.maxPage = action.payload.maxPage
            state.loading= false
            // state.previouslyLoaded = true 
        },
        setLoading: state => { state.loading = true },
        setError: (state, action) => { state.error = action.payload},
        clearError: state => { state.error = null },
        setPage: (state, action) => {
            state.page = action.payload
            state.loading= false
        },
        setPageSize: (state, action) => {
            state.pageSize = action.payload
            state.loading= false
        },
        setSort: (state, action) => {
            state.sort = action.payload
            state.loading= false
        },
    }
})

export const { setLoading, loadRequests, setError, clearError, setPage, setPageSize, setSort } = slice.actions

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
}

export const loadRequestsAsync = (filter,sort) => async dispatch => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/requests`,{params: {
            // page: page,
            // pageSize: pageSize,
            filter: filter,
            sort: sort
          }})
        console.log(res.data)
        dispatch(loadRequests(res.data))
    } catch (ex) {
        console.log(ex)
    }
}

export const setPageSizeAsync = (newPageSize) => async dispatch => {
    dispatch(setLoading())
    try {
        dispatch(setPageSize(newPageSize))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const setPageAsync = (newPage) => async dispatch => {
    dispatch(setLoading())
    try {
        dispatch(setPage(newPage))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const RequestsState = state => state.requests

export default slice.reducer