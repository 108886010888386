import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'


export const slice = createSlice({
    name: 'batch',
    initialState: {
        batches: null,
        loading: true,
        batch: null,
        error: null,
        openForm: false,
        rateOptions: [],
        batchZipJobs: [],
        batchZips: []
    },
    reducers: {
        setBatches: (state, action) => {
            state.batches = action.payload
            state.loading = false
        },
        setRateOptions: (state, action) => {
            state.rateOptions = action.payload
            state.loading = false
        },
        setBatchDetails: (state, action) => {
            state.batch = action.payload
            state.loading = false
        },
        setError: (state, action) => { state.error = action.payload },
        setLoading: state => { state.loading = true },
        clearError: state => { state.error = null },
        setOpenForm: (state, action) => { state.openForm = action.payload },
        setBatchZips: (state, action) => { 
            state.batchZips = action.payload
            state.loading = false
        },
        setBatchZipJobs: (state, action) => { 
            state.batchZipJobs = action.payload
            state.loading = false
        }
    }
})

export const { setBatches, setError, setLoading, clearError, setOpenForm, setRateOptions, setBatchDetails, setBatchZips, setBatchZipJobs } = slice.actions

export const loadBatchesAsync = () => async dispatch => {
    dispatch(setLoading())
    const config = {headers: {'Content-Type': 'application/json'}}
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/upload`, config)
        dispatch(setBatches(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const loadBatchDetailsAsync = (id) => async dispatch => {
    dispatch(setLoading())
    const config = {headers: {'Content-Type': 'application/json'}}
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/upload/${id}`, config)
        dispatch(setBatchDetails(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const loadRateOptionsAsync = (params) => async dispatch => {
    dispatch(setLoading())
    const {update} = params || {}
    const config = {headers: {'Content-Type': 'application/json'}}
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/upload/$rates`,{params: {update}}, config)
        dispatch(setRateOptions(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const loadBatchZipsAsync = () => async dispatch => {
    dispatch(setLoading())
    const config = {headers: {'Content-Type': 'application/json'}}
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Batch`, config)
        dispatch(setBatchZips(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const sendNewBatchZipJobAsync = batchId => async dispatch => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/Batch/Job`,{batchId})
        dispatch(getBatchZipJobsAsync())
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const  getBatchZipJobsAsync = () => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Batch/Job`)
        dispatch(setBatchZipJobs(res.data))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const BatchState = state => state.batch
export default  slice.reducer