import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'

import RenderExpandableCell from '../../utils/RenderExpandableCell';
import Spinner from '../../layouts/Spinner'

// MUI
import { TextField } from '@mui/material';
import { DataGrid, 
    GridToolbarContainer,GridToolbarColumnsButton,
    getGridStringOperators,GridToolbarExport
 } from '@mui/x-data-grid';

 const SearchBar = ({setSearchQuery}) => (
    <form style={{
        marginLeft: 5
      }}>
      <TextField
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"
      />
    </form>
  );
  
  const CustomToolbar = (props) => (
    <Fragment>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
      <SearchBar {...props} />
    </Fragment>
  );

const prepTableData =  (data) => {
    let returnArray = []

    data.map(c => {
        const id = c.id
        const description = Object.keys(c.description).length > 0 ? c.description.display : ''
        const startDate = c.period ? Object.keys(c.period).length > 0 ? moment(c.period.start).format('YYYY-MM-DD') : moment(c.period.end).format('YYYY-MM-DD') : ''
        const performer = c.primaryPerformer?.name ? c.primaryPerformer.name[0].text : ''
        const location = c.location ? c.location.name : ''
        const indication = c.json.contained?.map(i=>{if(i.resourceType == 'Condition') return i.code?.text}) || ''

        returnArray.push({id,description,startDate,performer, location, indication})
    })

    return returnArray

}


const EncountersDG = ({encounters}) => {
    let encounterData

    if(encounters) encounterData = prepTableData(encounters)
    const [tableData, setTableData] = useState(encounterData);

    useEffect(() => {
        setTableData(encounterData)
    }, [encounters])

    const requestSearch = (searchValue) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = encounterData.filter((o) => {
          return Object.keys(o).some((k) => {
            return searchRegex.test(o[k]?.toString());
          });
        });
        setTableData(filteredRows);
      };

    //Defines conditions Data Grid columns
    const columnsFormat = [
        {field: 'description', headerName: 'Description', width: 500,
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.description}/>
            }},
        {field: 'startDate', headerName: 'Start Date', width: 150,
            renderCell: (params) => {
                return params.row.startDate
            }},
        {field: 'performer', headerName: 'Performer', width: 250, 
            renderCell: (params) => {
                return params.row.performer 
            }},
        {field: 'location', headerName: 'Location', width: 500, 
            renderCell: (params) => {
                return params.row.location 
            }},
        {field: 'indication', headerName: 'Indication', width: 500, 
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.indication}/> 
            }}
    ]

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );



    if(tableData) {
        
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                                setSearchQuery: (searchVal) => requestSearch(searchVal),
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={tableData}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}

export default EncountersDG