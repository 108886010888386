export const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const httpStatusCodeText = code => {
    switch(code) {
        case 200: return "Complete"
        case 202: return "In Progress"
        case 404: return "Not Found"
        case 454: return "Timed Out"
        case 500: return "Error"
    }
}

export const highlightText = (string, color) => {
    return <span style={{backgroundColor: color}}> {string}</span>
}