import React, { Fragment } from 'react'

//import local component
import CommonwellLinkingDG from './CommonwellLinkingDG'

const CommonWellLinking = props => {

        return (
            <Fragment>
                <h1>Patients Requiring Linking</h1>
                <CommonwellLinkingDG />
            </Fragment>
        )
}

export default CommonWellLinking