import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'


export const slice = createSlice({
    name: 'archive',
    initialState: {
        patients: [],
        loading: true,
        batch: null,
        error: null,
        pageSize: 25,
        page: 0,
        peopleCount: null,
        maxPage: null,
        previouslyLoaded: null,
        sort: [{field:"lastName", sort:"asc"}],
    },
    reducers: {
        loadPeople: (state, action) => {
            const { people, page, PAGE_SIZE, totalPeople, maxPage } = action.payload
            state.patients = people
            if (page) state.page = action.payload.page - 1
            if (PAGE_SIZE) state.pageSize = action.payload.PAGE_SIZE
            if (totalPeople) state.peopleCount = action.payload.totalPeople
            if (maxPage) state.maxPage = action.payload.maxPage
            state.loading= false
            state.previouslyLoaded = true
        },
        setError: (state, action) => { state.error = action.payload },
        setLoading: state => { state.loading = true },
        clearError: state => { state.error = null },
        setPage: (state, action) => {
            state.page = action.payload
            state.loading= false
        },
        setPageSize: (state, action) => {
            state.pageSize = action.payload
            state.loading= false
        },
        setSort: (state, action) => {
            state.sort = action.payload
            state.loading= false
        },
    }
})

export const { loadPeople, setError, setLoading, clearError, setPage, setPageSize, setSort  } = slice.actions

export const getArchivedPatientsAsync = (page,pageSize,filter,sort) => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/archive`,{params: {
            page: page,
            pageSize: pageSize,
            filter: filter,
            sort: sort
          }})
        dispatch(loadPeople(res.data))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const updateArchiveStatusAsync = person => async dispatch => {
    dispatch(setLoading())
    const { isActive, id, reason} = person
    try {
        if (!person) return
        console.log(reason)
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/v1/archive/${id}`,{isActive,archiveReason:reason })
        window.location.reload()
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const setPageSizeAsync = (newPageSize) => async dispatch => {
    dispatch(setLoading())
    try {
        dispatch(setPageSize(newPageSize))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const setPageAsync = (newPage) => async dispatch => {
    dispatch(setLoading())
    try {
        dispatch(setPage(newPage))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const ArchiveState = state => state.archive
export default  slice.reducer