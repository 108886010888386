import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

export const slice = createSlice({
    name: 'patientQuery',
    initialState: {
        loading: true,
        filesLoading: true,
        queries: [],
        files: [],
        isTokenSet: false,
        error: null,
        recentRequest: null,
        reciprocalFiles: []
    },
    reducers: {
        setLoading: state => { state.loading = true },
        setFilesLoading: state => { state.filesLoading = true },
        setError: (state, action) => {
            state.error = action.payload
            state.loading = false
        },
        loadPatientQueries: (state, action) => {
            state.queries = action.payload
            state.recentRequest = action.payload[0]
            state.loading = false
        },
        loadPatientFiles: (state, action) => {
            state.files = action.payload
            state.filesLoading = false
        },
        loadReciprocalFiles: (state, action) => {
            state.reciprocalFiles = action.payload
            state.loading = false
        },
        clearError: state => { state.error = null },
    }
})

export const { setLoading, setError, clearError, loadPatientQueries, loadPatientFiles, loadReciprocalFiles } = slice.actions

export const  getQueriesByPatientAsync = patientId => async dispatch => {
    dispatch(setLoading())
    try {
        const params = { patientId }
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/PatientQuery`, { params })
        dispatch(loadPatientQueries(res.data))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const  getPatientFilesAsync = patientId => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/PatientDocument?patientId=${patientId}`)
        dispatch(loadPatientFiles(res.data))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const  downloadDocumentsAsync = patientId => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Document/${patientId}`)
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const  getReciprocalFilesAsync = patientId => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/PatientDocument/${patientId}/$files`)
        dispatch(loadReciprocalFiles(res.data))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const sendNewPatientQueryAsync = patientId => async dispatch => {
    // dispatch(setLoading())
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/PatientQuery`,{patientId})
        dispatch(getQueriesByPatientAsync(patientId))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const PatientQueryState = state => state.patientQuery

export default slice.reducer