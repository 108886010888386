import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'

import RenderExpandableCell from '../../utils/RenderExpandableCell';
import Spinner from '../../layouts/Spinner'

// MUI
import { TextField } from '@mui/material';
import { DataGrid, 
    GridToolbarContainer,GridToolbarColumnsButton,
    getGridStringOperators,GridToolbarExport
 } from '@mui/x-data-grid';

 const SearchBar = ({setSearchQuery}) => (
    <form style={{
        marginLeft: 5
      }}>
      <TextField
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"
      />
    </form>
  );
  
  const CustomToolbar = (props) => (
    <Fragment>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
      <SearchBar {...props} />
    </Fragment>
  );

const prepTableData =  (data) => {
    let returnArray = []

    data.map(c => {
        const id = c.id
        const description = Object.keys(c.code.snomed).length > 0 ? c.code.snomed.display : c.code.icd.display ? c.code.icd.display : '' || ''
        const category = Object.keys(c.category.loinc).length > 0 ? c.category.loinc.display : c.category.snomed?.display || ''
        const icd = c.code.icd ? c.code.icd.code ? c.code.icd.code : '' : ''
        const snomed = c.code.snomed ? c.code.snomed.code : ''
        const status = c.status ? c.status.display ? c.status.display : '' : ''
        const startDate = Object.keys(c.startDate).length > 0 ? moment(c.startDate.date).format('YYYY-MM-DD') : ''
        const asserter = c.asserter ? c.asserter : ''

        returnArray.push({id,description,category,icd,snomed,status,startDate,asserter})
    })

    return returnArray

}


const ConditionsDG = ({conditions}) => {
    let conditionData

    if(conditions) conditionData = prepTableData(conditions)
    const [tableData, setTableData] = useState(conditionData);

    useEffect(() => {
        setTableData(conditionData)
    }, [conditions])

    const requestSearch = (searchValue) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = conditionData.filter((o) => {
          return Object.keys(o).some((k) => {
            return searchRegex.test(o[k]?.toString());
          });
        });
        setTableData(filteredRows);
      };

    //Defines conditions Data Grid columns
    const columnsFormat = [
        {field: 'description', headerName: 'Description', width: 800,
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.description}/>
            }},
        {field: 'category', headerName: 'Category', width: 200,
            renderCell: (params) => {
                return params.row.category
            }},
        {field: 'icd', headerName: 'ICD', width: 100,
            renderCell: (params) => {
                return params.row.icd
            }},
        {field: 'snomed', headerName: 'SNOMED', width: 200, 
            renderCell: (params) => {
                return params.row.snomed 
            }},
        {field: 'status', headerName: 'Status', width: 100, 
            renderCell: (params) => {
                return params.row.status 
            }},
        {field: 'startDate', headerName: 'Start Date', width: 150,
            renderCell: (params) => {
                return params.row.startDate
            }},
        {field: 'asserter', headerName: 'Asserter', width: 150, 
            renderCell: (params) => {
                return params.row.asserter 
            }}
    ]

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );



    if(tableData) {
        
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                                setSearchQuery: (searchVal) => requestSearch(searchVal),
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={tableData}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default ConditionsDG