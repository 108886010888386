import React, { useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'


// mui
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid
} from "@mui/material";
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import { setSuccess } from "../people/peopleSlice"
import { getReciprocalFilesAsync } from '../patientQuery/patientQuerySlice';
import UploadFileIcon from '@mui/icons-material/UploadFile';

function PaperComponent(props) {
    return (
      <Draggable
        handle="#pn-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

const ProgressNote = ({person}) => {
    const dispatch = useDispatch()

    const initialState = {
        isDialogOpen: false,
        selectedFile: null,
        fileError: null
    }

    const [localState, setLocalState] = useState(initialState)
    const { isDialogOpen, selectedFile, fileError } = localState

    const handleClickOpen = () => {
        setLocalState({...localState, isDialogOpen: true})
    }
    const handleClose = () => {
        setLocalState(initialState)
    }
    const handleSubmit = async e => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('patientId', person.id)

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        try {
            const res = await axios
                .post(`${process.env.REACT_APP_API_BASE}/v1/ProgressNote`, formData, config)
                .catch(error => {
                    if (error) {
                        console.log(error.response.data)
                        setLocalState({
                            ...localState,
                            fileError: error.response.data.error,
                            isDialogOpen: true
                        })
                    }
                })

            if (res) { 
                dispatch(setSuccess(true))
                dispatch(getReciprocalFilesAsync(person.id))
                setLocalState(initialState)
            }
        } catch (ex) {
            console.log(ex)
        }
    }

    const onFileChange = e => setLocalState({...localState, selectedFile: e.target.files[0] })

    return (<>
        <Box
            display='flex'
            justifyContent='flex-mid'>
            <Button
                variant="contained"
                size={'small'}
                startIcon={<UploadFileIcon />}
                onClick={() => {handleClickOpen()}}>
                Upload Progress Note
            </Button>
        </Box>

        <Dialog
            open={isDialogOpen}
            id='submitProgressNote'
            onClose={handleClose}
            aria-labelledby={'pn-dialog-title'}
            fullWidth={true}
            maxWidth={'md'}
            PaperComponent={PaperComponent}>
                
            <DialogTitle
                id={'pn-dialog-title'}>
                Upload Progress Note
            </DialogTitle>
            <DialogContent>
                {fileError && <DialogContentText>
                    future error
                </DialogContentText>}

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DialogContentText>Upload a Progress Note for {person.firstName} {person.lastName} </DialogContentText>
                        <DialogContentText>{selectedFile?.name}</DialogContentText>
                        <Button
                            variant={'outlined'}
                            component={'label'}
                            size={'small'}
                        >
                            Choose File
                            <input
                                type={'file'}
                                name={'file'}
                                onChange={onFileChange}
                                hidden
                            />
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color={'primary'}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color={'primary'} disabled={!selectedFile}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>

    </>)
}

export default ProgressNote