import React, { useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"

import moment from 'moment'

// components
import BatchDetailTable from './BatchDetailTable'
import Spinner from '../../layouts/Spinner'

// context
import { BatchState, loadBatchDetailsAsync } from "./batchSlice"
import {AuthState} from "../auth/authSlice"

// mui
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link, Typography, Chip
} from "@mui/material";

const BatchDetails = props => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const { batch, loading } = useSelector(BatchState)

    useEffect(() => {
        dispatch(loadBatchDetailsAsync(id))
    }, [])

    if (loading) return <Spinner />

    return (
        <Fragment>
            <h1>Batch Details</h1>
            <p>Import Id: {batch.id}</p>
            <p>Created At: {moment(batch.createdAt).format("YYYY-MM-DD HH:mm:ss")}</p>
            <p>Source: {batch.source}</p>
            <p>Passed Validation: {batch.isValid ? "Yes" : "No"}</p>
            <p>Lines: {batch.summary.lines}</p>
            <p>Created/Existing/Error: {batch.summary.created}/{batch.summary.existing}/{batch.summary.error}</p>

            <BatchDetailTable details={batch.Details} batch={batch}/>
        </Fragment>
    )
}

export default BatchDetails