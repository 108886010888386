import React, { useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import fileDownload from 'js-file-download'
import moment from 'moment'

// mui
import {Paper,Chip, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Tooltip, Typography} from '@mui/material'
import DownloadForOfflineSharpIcon from '@mui/icons-material/DownloadForOfflineSharp';
import IconButton from '@mui/material/IconButton';

// components
import Spinner from '../../layouts/Spinner'

// context
import { PatientQueryState, getReciprocalFilesAsync } from '../patientQuery/patientQuerySlice'
import ProgressNote from "../reciprocalData/ProgressNote";
import DeleteProgressNote from './DeleteProgressNote'



const ReciprocalDataHistory = ({person}) => {
    const dispatch = useDispatch()

    const { id:patientId } = person

    useEffect(() => {
        if (patientId) dispatch(getReciprocalFilesAsync(patientId))
    }, [patientId])
    

    const { loading, reciprocalFiles } = useSelector(PatientQueryState)

    const {ClientFiles} = reciprocalFiles

    const Title = () => {
        return <Typography variant={'h6'} style={{marginTop: '30px'}} >Network Contributions {ClientFiles && <Chip sx={{marginLeft: 1}} color='secondary' label={ ClientFiles.length}/>}</Typography>
    }

    const handleClick = async (id) => {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/PatientDocument/${id}`, { responseType: 'blob' })
        const fileName = person.lastName + '-' + res.headers.filename
        fileDownload(res.data, fileName)
    }

    if (loading) return (
        <Fragment>
            <Title />
            <Spinner />
        </Fragment>
    )


    return (
        <Fragment>
            <Title />
            <ProgressNote person={person} />
            {ClientFiles && ClientFiles.length === 0 && <p>No files</p>}
            {ClientFiles && ClientFiles.length > 0 && (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">ID</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Document Type</TableCell>
                                <TableCell align="left">Document Description</TableCell>
                                <TableCell align="left">File Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ClientFiles.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    style={{backgroundColor: row.fhirDocumentType === 'Composite' ? '#efefef' : 'inherit'}}
                                >
                                    <TableCell align='center' scope="row">
                                    <IconButton aria-label="download" 
                                                onClick={() => {handleClick(row.id)}}
                                                >
                                        <Tooltip title="Download">
                                            <DownloadForOfflineSharpIcon />
                                        </Tooltip>
                                    </IconButton>
                                    </TableCell>
                                    <TableCell align='center' scope="row">
                                        <DeleteProgressNote doc={row} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="left">{moment(row.fhirDate).isValid() ? moment(row.fhirDate).format('YYYY-MM-DD') : ''}</TableCell>
                                    <TableCell align="left">{row.fhirDocumentType}</TableCell>
                                    <TableCell align="left">{row.fhirDescription}</TableCell>
                                    <TableCell align="left">{row.fileName}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Fragment>

    )
}

export default ReciprocalDataHistory