import React, { Fragment, useState } from 'react'
import Papa from "papaparse"

// mui
import {Button, Grid, Select, InputLabel, FormControl,} from "@mui/material";


const BatchTemplate = props => {

    const csvFile = require('../../uploadTemplates/Unit387_CSV_template.csv')
    const defFile = require('../../uploadTemplates/Unit387_Column_Definitions.csv')
    const sampleFile = require('../../uploadTemplates/sample_patient_data.csv')

    const initialState = {
        open: false,
        file: csvFile
    }
    
    const [dialog, setDialog] = useState(initialState)
    let { file } = dialog

const onClick = async () => {

    Papa.parse(file, {
        download: true,
        newline: "\n",

        complete: function (results) {
            let csvContent = "data:text/csv;charset=utf-8," 
            + results.data.map(e => e.join(",")).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "Unit387_CSV_download.csv");
            document.body.appendChild(link); // Required for FF
            link.click()
            
        }
    });
  };

  const onChange = e => setDialog({...dialog, [e.target.name]: e.target.value})

    return <Fragment>
                <Grid item xs={12}>
                    <FormControl variant="outlined" style={{minWidth: 150}} size="small">
                        <InputLabel htmlFor="outlined-age-native-simple">Select File</InputLabel>
                        <Select
                            native
                            required
                            value={file}
                            onChange={onChange}
                            label="Select File"
                            inputProps={{
                                name: 'file',
                                id: 'file',
                            }}
                            >
                            <option value={csvFile} key='cleanFile'>Clean Template</option>
                            <option value={defFile} key='def'>Column Definitions</option>
                            <option value={sampleFile} key='sample'>Sample File</option>
                        </Select>
                    </FormControl> 
                    <Button onClick={onClick} variant={'contained'} color={'primary'} size='small' style={{marginLeft: 5, marginTop: 5}} >Download</Button>
                </Grid>
            </Fragment>
}

export default BatchTemplate