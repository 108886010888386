import React, {useState} from 'react'
import { useDispatch} from "react-redux"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import {setNoFacility} from '../people/peopleSlice'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NoFacilityPopUp = ({message}) =>  {
        const dispatch = useDispatch()
    
        const [open] = useState(true); 
        //Leave this true since we are not using a button
    
        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            dispatch(setNoFacility(null))
          };

    return (
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
            <Alert onClose={handleClose} severity="info">{message}</Alert>
        </Snackbar> 
    )
}

export default NoFacilityPopUp