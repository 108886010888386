import axios from "axios";
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'

// MUI
import {
    Button, Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import DownloadForOfflineSharpIcon from '@mui/icons-material/DownloadForOfflineSharp';

// context
import { BatchState, sendNewBatchZipJobAsync } from './batchSlice'

const DownloadBatchCompositesForm = props => {
    const dispatch = useDispatch()
    const { batchZips, batchZipJobs } = useSelector(BatchState)

    const [state, setState] = useState({
        downloadBatchId: ''
    })

    const { downloadBatchId } = state
    const onChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const onStartJobClick = async e => {
        e.preventDefault()
        try {
            await dispatch(sendNewBatchZipJobAsync(downloadBatchId))
        } catch (ex) {
            console.log(ex)
        }
    }

    const handleDownloadClick = async (id) => {
        const res = await axios.get(
            `${process.env.REACT_APP_API_BASE}/v1/Batch/${id}`, {
            })

        const { signedUrl, fileName } = res.data

        const aTag = document.createElement('a')
        aTag.href = signedUrl
        aTag.target = '_blank'
        aTag.setAttribute('download', fileName)
        document.body.appendChild(aTag)
        aTag.click()
        aTag.remove()
    }

    return <Fragment>
        <Card variant={'outlined'}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} style={{marginBottom: '15px', textTransform: 'uppercase'}}  >
                    Download Batch Composites
                </Typography>
                <Grid container spacing={3}>

                    <Grid item xs={5}>
                        <FormControl fullWidth>
                        <TextField
                            type='text'
                            id='downloadBatchId'
                            name='downloadBatchId'
                            label='Batch Id'
                            value={downloadBatchId}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                            required
                            size={'small'}
                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={onStartJobClick} variant={'contained'} color={'primary'} disabled={!downloadBatchId}>Create Zip</Button>
                    </Grid>
                </Grid>
                {batchZipJobs.length > 0 && <Table sx={{ minWidth: 250, width: 250 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Batch ID</TableCell>
                                <TableCell align="left">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {batchZipJobs.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.batchId}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Generating Zip
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
                {batchZips.length > 0 && <Table sx={{ minWidth: 250, width: 250 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">Batch ID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {batchZips.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align='center' scope="row">
                                    <IconButton aria-label="download" 
                                                onClick={() => {handleDownloadClick(row.id)}}
                                                >
                                        <Tooltip title="Download">
                                            <DownloadForOfflineSharpIcon />
                                        </Tooltip>
                                    </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.batchId}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
            </CardContent>
        </Card>
    </Fragment>
}

export default DownloadBatchCompositesForm