import React, { useEffect, Fragment, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import moment from 'moment'

// mui
import {Paper,Chip, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Tooltip, Typography, Grid, Card, CardContent} from '@mui/material'
import DownloadForOfflineSharpIcon from '@mui/icons-material/DownloadForOfflineSharp';
import DownloadIcon from '@mui/icons-material/Download'
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'

// components
import Spinner from '../../layouts/Spinner'

// context
import { PatientQueryState, getPatientFilesAsync } from '../patientQuery/patientQuerySlice'

import DownloadDocuments from "../patientQuery/DownloadDocuments";
import FacilityComposite from '../patientQuery/FacilityComposite'

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress color={'secondary'} variant="determinate" {...props} />
            </Box>
            {/*<Box sx={{ minWidth: 35 }}>*/}
            {/*    <Typography style={{fontSize: '0.65em'}} variant="body2" color="text.secondary">{`${Math.round(*/}
            {/*        props.value,*/}
            {/*    )}%`}</Typography>*/}
            {/*</Box>*/}
        </Box>
    );
}

const ClientFilesList = ({person}) => {
    const dispatch = useDispatch()

    const { id:patientId, isDownloading } = person

    const [compositeBar, setCompositeBar] = useState(null)
    const [cdaBar, setCdaBar] = useState(null)
    const [condensedBar, setCondensedBar] = useState(null)
    const [zipBar, setZipBar] = useState(null)

    useEffect(() => {
        if (patientId) dispatch(getPatientFilesAsync(patientId))
    }, [patientId])

    const { loading, filesLoading, files } = useSelector(PatientQueryState)

    const { ClientFiles, Composite, Consolidated, Condensed, Zip } = files

    const CompositeGrid = () => {

        return (
            <Grid container spacing={4} mt={1} sx={{marginBottom: "10px"}}>
                {Zip && <Grid item xs={3}>
                    <Card style={{backgroundColor: '#f9f9f9', cursor: 'pointer'}} onClick={null} >
                        <CardContent>
                            <span style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                <IconButton aria-label="download"  onClick={() => {handleClick(Zip.id, 'composite')}} >
                                            <Tooltip title="Download">
                                                <DownloadIcon />
                                            </Tooltip>
                                        </IconButton>
                                {/* {<ViewPDF fileId={Consolidated.id} />} */}
                                <span style={{marginLeft: "10px"}}>Zip Folder</span>
                            </span>
                            <Typography style={{color: '#6e6e6e', fontSize: '0.7em'}} variant='caption' display='block'>
                                A compressed zip folder of composite, CDA, and condensed
                            </Typography>
                            {zipBar && <LinearProgressWithLabel value={zipBar} />}

                        </CardContent>
                    </Card>
                </Grid>}
                {Composite && <Grid item xs={3}>
                    <Card style={{backgroundColor: '#f9f9f9', cursor: 'pointer'}} onClick={null} >
                        <CardContent>
                            <span style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                <IconButton aria-label="download"  onClick={() => {handleClick(Composite.id, 'composite')}} >
                                            <Tooltip title="Download">
                                                <DownloadIcon />
                                            </Tooltip>
                                        </IconButton>
                                    {/* {<ViewPDF fileId={Consolidated.id} />} */}
                                <span style={{marginLeft: "10px"}}>Composite</span>
                            </span>
                            <Typography style={{color: '#6e6e6e', fontSize: '0.7em'}} variant='caption' display='block'>
                                A comprehensive collection of all records
                            </Typography>
                            {compositeBar && <LinearProgressWithLabel value={compositeBar} />}

                        </CardContent>
                    </Card>
                </Grid>}
                {Consolidated && <Grid item xs={3}>
                    <Card style={{backgroundColor: '#f9f9f9', cursor: 'pointer'}} onClick={null}  >
                        <CardContent>
                            <Typography >
                                <span style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                    <IconButton aria-label="download"  onClick={() => {handleClick(Consolidated.id, 'cda')}} >
                                            <Tooltip title="Download">
                                                <DownloadIcon />
                                            </Tooltip>
                                        </IconButton>
                                    {/* {<ViewPDF fileId={Consolidated.id} />} */}
                                    <span style={{marginLeft: "10px"}}>Consolidated CDA</span>
                                </span>
                                <Typography style={{color: '#6e6e6e', fontSize: '0.7em'}} variant='caption' display='block'>
                                    A summary of structured patient data
                                </Typography>
                                {cdaBar && <LinearProgressWithLabel value={cdaBar} />}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>}
                {Condensed && <Grid item xs={3}>
                    <Card style={{backgroundColor: '#f9f9f9', cursor: 'pointer'}} onClick={null} >
                        <CardContent>
                            <span style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                <IconButton aria-label="download"  onClick={() => {handleClick(Condensed.id, 'composite')}} >
                                            <Tooltip title="Download">
                                                <DownloadIcon />
                                            </Tooltip>
                                        </IconButton>
                                {/* {<ViewPDF fileId={Consolidated.id} />} */}
                                <span style={{marginLeft: "10px"}}>Condensed Composite</span>
                            </span>
                            <Typography style={{color: '#6e6e6e', fontSize: '0.7em'}} variant='caption' display='block'>
                                A working copy of the complete Composite
                            </Typography>
                            {condensedBar && <LinearProgressWithLabel value={condensedBar} />}

                        </CardContent>
                    </Card>
                </Grid>}
            </Grid>
        )
    }

    const Title = () => {
        return <Typography variant={'h4'} style={{marginTop: '30px'}} >Documents Available {ClientFiles && <Chip sx={{marginLeft: 1}} color='secondary' label={ClientFiles.length || 0}/>}</Typography>
    }

    const handleClick = async (id, showBar = null) => {
        // const res = await axios.get(
        //     `${process.env.REACT_APP_API_BASE}/v1/PatientDocument/${id}`,
        //     { responseType: 'blob', onDownloadProgress: (progressEvent) => {
        //             const { progress } = progressEvent
        //             if (showBar === 'composite') setCompositeBar(progress * 100)
        //             if (showBar === 'cda') setCdaBar(progress * 100)
        //
        //         }})
        // const fileName = res.headers.filename
        // fileDownload(res.data, fileName)

        const res = await axios.get(
            `${process.env.REACT_APP_API_BASE}/v1/PatientDocument/${id}/$alt`, {
            })

        const { signedUrl, fileName } = res.data

        const aTag = document.createElement('a')
        aTag.href = signedUrl
        aTag.target = '_blank'
        aTag.setAttribute('download', fileName)
        document.body.appendChild(aTag)
        aTag.click()
        aTag.remove()
    }

    if (filesLoading) return (
        <Fragment>
            <Title />
            <Spinner />
        </Fragment>
    )

    if (isDownloading){
        return (
        <Fragment>
            <Title />
            <p>Your documents are currently downloading. They will be available shortly.</p>
        </Fragment>)
    }

    return (
        <Fragment>
            <Title />
            <Grid container spacing={2}  style={{marginTop: 5, marginLeft: 3.}}>
                    <DownloadDocuments person={person} />
                    {ClientFiles.length > 0 && !isDownloading && <FacilityComposite person={person} />}
            </Grid>
            {ClientFiles.length === 0 && <p>No files</p>}
            {ClientFiles.length > 0 && !isDownloading && (
                <div>
                    
                    <CompositeGrid/>

                    <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"></TableCell>
                                {/* <TableCell align="left"></TableCell> */}
                                <TableCell align="left">Document ID</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Document Type</TableCell>
                                <TableCell align="left">Document Description</TableCell>
                                <TableCell align="left">Author</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ClientFiles.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    style={{backgroundColor: ['Composite', 'Consolidated CDA'].includes(row.fhirDocumentType) ? '#efefef' : 'inherit'}}
                                >
                                    <TableCell align='center' scope="row">
                                    <IconButton aria-label="download" 
                                                onClick={() => {handleClick(row.id)}}
                                                >
                                        <Tooltip title="Download">
                                            <DownloadForOfflineSharpIcon />
                                        </Tooltip>
                                    </IconButton>
                                    </TableCell>
                                    {/* <TableCell align='center' scope="row">
                                    {<ViewPDF fileId={row.id} />}
                                    </TableCell> */}
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="left">{moment(row.Supplemental?.encounterStart).isValid() ? moment(row.Supplemental?.encounterStart).format('YYYY-MM-DD') : ''}</TableCell>
                                    <TableCell align="left">{row.fhirDocumentType}</TableCell>
                                    <TableCell align="left">{row.fhirDescription}</TableCell>
                                    <TableCell align="left">{row.Supplemental?.source}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </div>
            )}

        </Fragment>

    )
}

export default ClientFilesList