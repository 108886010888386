import React, { useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

// context
import { FhirResourceState, getEncountersAsync} from './fhirResourceSlice'
import { PeopleState, getPersonAsync } from "../people/peopleSlice"


//import local component
import ChartHeader from '../people/ChartHeader'
import EncountersDG from './EncountersDG'

const Encounters = () => {
    const { id } = useParams()

    const { encounters } = useSelector(FhirResourceState)
    const { person } = useSelector(PeopleState)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPersonAsync(id))
        dispatch(getEncountersAsync(id))
    }, [])

        return (
            <Fragment>
                {person &&  <div style={{marginLeft: 10, marginTop: "30px"}}>
                                <ChartHeader person={person} />
                                <h1>Encounters</h1>
                                <EncountersDG encounters={encounters}/>
                            </div>}
            </Fragment>
        )
}

export default Encounters