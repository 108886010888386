import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'

import RenderExpandableCell from '../../utils/RenderExpandableCell';
import Spinner from '../../layouts/Spinner'

// MUI
import { TextField, Typography } from '@mui/material';
import { DataGrid, 
    GridToolbarContainer,GridToolbarColumnsButton,
    getGridStringOperators,GridToolbarExport
 } from '@mui/x-data-grid';

 const SearchBar = ({setSearchQuery}) => (
    <form style={{
        marginLeft: 5
      }}>
      <TextField
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"
      />
    </form>
  );

  const CustomToolbar = (props) => (
    <Fragment>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
      <SearchBar {...props} />
    </Fragment>
  );

const prepTableData =  (data) => {
    let returnArray = []
    if(data){
        data.map(c => {
            if(!c.relationship) return
            const id = c.id
            const condition = c.condition?.length > 0 ? c.condition : ''
            const relationship = c.relationship ? c.relationship : ''
            const bornDate = c.bornDate ? c.bornDate : ''
            const asserter = c.asserter ? c.asserter : ''
            const status = c.status ? c.status : ''
            const gender = c.gender ? c.gender : ''
            const deceased = c.deceased ? c.deceased : ''

            returnArray.push({id,bornDate, condition,relationship, asserter, status, gender, deceased })
        })
    }

    return returnArray

}


const FamilyHistoryDG = ({familyHistory}) => {
    let familyHistoryData
    if(familyHistory) familyHistoryData = prepTableData(familyHistory)
        
    const [tableData, setTableData] = useState(familyHistoryData);

    useEffect(() => {
        setTableData(familyHistoryData)
    }, [familyHistory])

    //Defines Data Grid columns
    const columnsFormat = [
        {field: 'relationship', headerName: 'Relationship', width: 300,
            renderCell: (params) => {
                return(
                    <Typography variant="body2">
                        <span >{params.row.relationship.toUpperCase()}</span><br/>
                        {params.row.bornDate && <span style={{color:'#8a8988', fontSize:14}}>Born: {params.row.bornDate} {params.row.deceased && <span style={{color:'#8a8988', fontSize:14}}><br/>Deceased</span>}</span>}
                        {!params.row.bornDate && params.row.deceased && <span style={{color:'#8a8988', fontSize:14}}>Deceased</span>}
                    </Typography>
                )
            }},
        {field: 'asserter', headerName: 'Asserter', width: 300,
            renderCell: (params) => {
                return params.row.asserter.toUpperCase()
            }},
        {field: 'condition', headerName: 'Condition', width: 500, 
            renderCell: (params) => {
                if(params.row.condition === '') return 'No Condition Listed'
                else {
                return (<Fragment>
                            <Typography variant="body2">
                            {params.row.condition.map(c => {
                                return(
                                        <Typography variant="body2">
                                            <span >{c.condition.toUpperCase()}</span><br/>
                                            {c.onsetAge && <span style={{color:'#8a8988', fontSize:14}}>Onset Age: {c.onsetAge.value}{c.contributedToDeath && '; Cause of Death'}</span>}
                                            {!c.onsetAge && c.contributedToDeath && <span style={{color:'#8a8988', fontSize:14}}>Cause of Death</span>}
                                        </Typography>
                                    )
                            })}
                            </Typography>
                        </Fragment>)
                }
            }},
        {field: 'status', headerName: 'Status', width: 150, 
            renderCell: (params) => {
                return params.row.status.toUpperCase()
            }},
    ]

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

      const requestSearch = (searchValue) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = familyHistoryData.filter((o) => {
          return Object.keys(o).some((k) => {
            return searchRegex.test(o[k]?.toString());
          });
        });
        setTableData(filteredRows);
      };
    

    if(tableData) {
        
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: 'relationship', sort: 'asc' }],
                          },
                        }}
                        componentsProps={{
                            toolbar: {
                                setSearchQuery: (searchVal) => requestSearch(searchVal),
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={tableData}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default FamilyHistoryDG