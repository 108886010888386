import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'

import RenderExpandableCell from '../../utils/RenderExpandableCell';
import Spinner from '../../layouts/Spinner'

// MUI
import { TextField } from '@mui/material';
import { DataGrid, 
    GridToolbarContainer,GridToolbarColumnsButton,
    getGridStringOperators,GridToolbarExport
 } from '@mui/x-data-grid';

 const SearchBar = ({setSearchQuery}) => (
    <form style={{
        marginLeft: 5
      }}>
      <TextField
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"
      />
    </form>
  );

  const CustomToolbar = (props) => (
    <Fragment>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
      <SearchBar {...props} />
    </Fragment>
  );

const prepTableData =  (data) => {
    let returnArray = []
    if(data){
        data.map(c => {
            const id = c.id
            const description = Object.keys(c.code.snomed).length > 0 ? c.code.snomed.display : c.code.icd.display ? c.code.icd.display : c.code.unidentified.display
            const category = c.category ? c.category : ''
            const reaction = c.reaction?.display ? c.reaction.display : ''
            const severity = c.reaction?.severity ? c.reaction.severity : ''
            const status = c.status?.display ? c.status.display : ''
            const startDate = Object.keys(c.startDate).length > 0 ? moment(c.startDate.date).format('YYYY-MM-DD') : ''
            const asserter = c.asserter ? c.asserter : ''

            returnArray.push({id,description, category, asserter, startDate, status, reaction, severity})
        })
    }

    return returnArray

}


const AllergyIntolerancesDG = ({allergyIntolerances}) => {
    let allergyIntolerancesData
    if(allergyIntolerances) allergyIntolerancesData = prepTableData(allergyIntolerances)
        
    const [tableData, setTableData] = useState(allergyIntolerancesData);

    useEffect(() => {
        setTableData(allergyIntolerancesData)
    }, [allergyIntolerances])

    //Defines conditions Data Grid columns
    const columnsFormat = [
        {field: 'description', headerName: 'Substance', width: 400,
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.description.toUpperCase()}/>
            }},
        {field: 'category', headerName: 'Category', width: 200,
            renderCell: (params) => {
                return params.row.category.toUpperCase()
            }},
        {field: 'reaction', headerName: 'Reaction', width: 200,
            renderCell: (params) => {
                return params.row.reaction.toUpperCase()
            }},
        {field: 'severity', headerName: 'Severity', width: 200, 
            renderCell: (params) => {
                return params.row.severity.toUpperCase()
            }},
        {field: 'status', headerName: 'Status', width: 100, 
            renderCell: (params) => {
                return params.row.status.toUpperCase() 
            }},
        {field: 'startDate', headerName: 'Start Date', width: 150,
            renderCell: (params) => {
                return params.row.startDate
            }},
        {field: 'asserter', headerName: 'Asserter', width: 150, 
            renderCell: (params) => {
                return params.row.asserter 
            }}
    ]

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

      const requestSearch = (searchValue) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = allergyIntolerancesData.filter((o) => {
          return Object.keys(o).some((k) => {
            return searchRegex.test(o[k]?.toString());
          });
        });
        setTableData(filteredRows);
      };
    

    if(tableData) {
        
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                                setSearchQuery: (searchVal) => requestSearch(searchVal),
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={tableData}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default AllergyIntolerancesDG