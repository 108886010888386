import React, { useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// components
import ChartDemographics from "./ChartDemographics"
import ChartHeader from './ChartHeader'
import ClientFilesList from './ClientFilesList'
import RequestHistory from './RequestHistory'
import ReciprocalDataHistory from './ReciprocalDataHistory'
import Spinner from '../../layouts/Spinner'

// context
import { PeopleState, getPersonAsync } from "./peopleSlice"
import {AuthState} from "../auth/authSlice"

// mui
import { Grid } from "@mui/material";
import ProgressNoteAlert from '../reciprocalData/ProgressNoteAlert'
import NoFacilityPopUp from '../patientQuery/NoFacilityPopUp'
import ErrorAlert from './ErrorAlert'

const PersonDetail = props => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { user } = useSelector(AuthState)

    const { person, loading, success, noFacility, error } = useSelector(PeopleState)

    useEffect(() => {
        dispatch(getPersonAsync(id))
    }, [])
    

    if (loading) return (<Spinner />)

    return (
        <Fragment>
            {success &&  <ProgressNoteAlert props = {{message:'Progress Note Added'}} />  }
            {noFacility && <NoFacilityPopUp message={noFacility}/>}
            {error && <ErrorAlert message={error}/>}

            <div style={{marginLeft: 10, marginTop: "30px"}}>
                <ChartHeader person={person} />
                <Grid container spacing={2}>
                    <Grid item xs={12} xl={6}>
                        <ChartDemographics person={person} />
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        <RequestHistory person={person} />
                        {user.organization.defaultDatasourceId === 'HG' && <ReciprocalDataHistory person={person} />}
                    </Grid>
                </Grid>

                <ClientFilesList person={person} />
            </div>

        </Fragment>
    )
}

export default PersonDetail

