import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'

import RenderExpandableCell from '../../utils/RenderExpandableCell';
import Spinner from '../../layouts/Spinner'

// MUI
import { TextField } from '@mui/material';
import { DataGrid, 
    GridToolbarContainer,GridToolbarColumnsButton,
    getGridStringOperators,GridToolbarExport
 } from '@mui/x-data-grid';

 const SearchBar = ({setSearchQuery}) => (
    <form style={{
        marginLeft: 5
      }}>
      <TextField
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"
      />
    </form>
  );
  
  const CustomToolbar = (props) => (
    <Fragment>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
      <SearchBar {...props} />
    </Fragment>
  );
const prepTableDate =  (data) => {
    let returnArray = []

    data.map(c => {
        const id = c.id
        const description = c.code?.text || ''
        const date = c.startDate ? moment(c.startDate).format('YYYY-MM-DD') : ''
        const status = c.status ? c.status : ''
        const performer = c.performer?.length > 0 ? c.performer[0] : ''
        const indication = c.reasonCode?.length > 0 ? c.reasonCode[0] : ''

        returnArray.push({id,description, date, status,performer, indication})
    })

    return returnArray

}


const ProceduresDG = ({procedures}) => {
    let procedureData

    if(procedures) procedureData = prepTableDate(procedures)
    const [tableData, setTableData] = useState(procedureData);

    useEffect(() => {
        setTableData(procedureData)
    }, [procedures])

    const requestSearch = (searchValue) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = procedureData.filter((o) => {
          return Object.keys(o).some((k) => {
            return searchRegex.test(o[k]?.toString());
          });
        });
        setTableData(filteredRows);
      };
    //Defines conditions Data Grid columns
    const columnsFormat = [
        {field: 'description', headerName: 'Description', width: 600,
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.description}/>
            }},
        {field: 'date', headerName: 'Date', width: 150,
            renderCell: (params) => {
                return params.row.date
            }},
        {field: 'status', headerName: 'Status', width: 150, 
            renderCell: (params) => {
                return params.row.status 
            }},
        {field: 'performer', headerName: 'Performer', width: 300, 
            renderCell: (params) => {
                return params.row.performer 
            }},
        {field: 'indication', headerName: 'Indication', width: 500, 
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.indication}/> 
            }}
    ]

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

    if(tableData) {
        
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                                setSearchQuery: (searchVal) => requestSearch(searchVal),
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={tableData}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default ProceduresDG