import React, { useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

// context
import { FhirResourceState, getConditionsAsync} from './fhirResourceSlice'
import { PeopleState, getPersonAsync } from "../people/peopleSlice"


//import local component
import ChartHeader from '../people/ChartHeader'
import ConditionsDG from './ConditionsDG'

const Conditions = () => {
    const { id } = useParams()

    const { conditions } = useSelector(FhirResourceState)
    const { person } = useSelector(PeopleState)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPersonAsync(id))
        dispatch(getConditionsAsync(id))
    }, [])

        return (
            <Fragment>
                {person &&  <div style={{marginLeft: 10, marginTop: "30px"}}>
                                <ChartHeader person={person} />
                                <h1>Conditions</h1>
                                <ConditionsDG conditions={conditions}/>
                            </div>}
            </Fragment>
        )

}

export default Conditions