import React, { useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// context
import { PeopleState, getPeopleAsync, mergePatientsAsync } from '../people/peopleSlice'

// MUI
import {
    Button, Card,
    CardContent,
    FormControl,
    Grid,
    TextField,
    Typography,
    Autocomplete 
} from "@mui/material"
import Spinner from '../../layouts/Spinner'
import MergeAlert from './MergeAlert'


const prepDisplay = (list) => {
    const returnData = []

    list.map(l => {
        let display = `${l.searchName} | ${l.gender.toUpperCase()} | ${l.birthDate}`
        const returnObject = {display,...l}
        returnData.push(returnObject)
    })
    return returnData
}

const MergePatients = props => {

    const { people, merged } = useSelector(PeopleState)
    const [disableSubmit, setDisableSubmit] = React.useState(true)
    const [peopleList, setPeopleList] = React.useState(null)
    const [dialog, setDialog] = React.useState({keepPatientId:null,removePatientId:null,keepPatient:null,removePatient:null})
    let { keepPatient, removePatient, keepPatientId, removePatientId} = dialog
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPeopleAsync())
    }, [])

    useEffect(() => {
        if(keepPatient && removePatient) setDisableSubmit(false)
    }, [keepPatient, removePatient])

    useEffect(() => {
        if(people.length > 0)  setPeopleList(prepDisplay(people))
    }, [people])

    const setKeep = e =>{
        const value = peopleList.find(i => {return i.display === e})
        setDialog({...dialog, keepPatientId: value.id, keepPatient:e})
    }

    const setRemove = e =>{
        const value = peopleList.find(i => {return i.display === e})
        setDialog({...dialog, removePatientId: value.id, removePatient: e})
    }

    const onClick = async e => {
        dispatch(mergePatientsAsync(keepPatientId, removePatientId))
        setDialog({ removePatient: null, keepPatient:null, removePatientId:null, keepPatientId: null})
        
    }

    if(peopleList && peopleList.length > 0)  {
    return (
            <Fragment>
                {merged &&  <MergeAlert />  }
                <h1>Merge Patients</h1>

                <Card variant={'outlined'}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} style={{marginBottom: '15px', textTransform: 'uppercase'}}  >
                    Select Patients to merge
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" style={{minWidth: 200}} required>
                            <Autocomplete
                                id="keep"
                                value={keepPatient}
                                options={peopleList.map(option=>option.display)}
                                sx={{ width: 500 }}
                                size={'small'}
                                onChange={(event, newValue) => {
                                   setKeep(newValue)
                                  }}
                                isOptionEqualToValue={(option, value) => option.searchName === value.value}
                                renderInput={(params) =>  <TextField {...params} label='Patient to keep'/>}
                                />
                        </FormControl>     
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" style={{minWidth: 200}} required>
                            <Autocomplete
                                id="remove"
                                value={removePatient}
                                options={peopleList.map(option=>option.display)}
                                sx={{ width: 500 }}
                                size={'small'}
                                onChange={(event, newValue) => {
                                    setRemove(newValue)
                                   }}
                                isOptionEqualToValue={(option, value) => option.searchName === value.value}
                                renderInput={(params) =>  <TextField {...params} label='Patient to remove'/>}
                                />
                        </FormControl>     
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={onClick} variant={'contained'} color={'primary'} disabled={disableSubmit} >Merge</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
            </Fragment>
        )
    }
    else 
         return <Spinner />

}

export default MergePatients