import React from 'react'

import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";


// const MySpinner = styled(Skeleton, {})(({theme, open}) => ({
//     width: "90%",
//     margin: 'auto',
//     marginTop: '5px',
//     height: '100%'
// }))

const StyledSkeleton = styled(Skeleton)({
    width: "90%",
    margin: 'auto',
    marginTop: '50px',
    height: '20px'
})


const Spinner = () => {
    return (
        <StyledSkeleton />
    )
}

export default Spinner