import React, { Fragment } from 'react'


//import local component
import ReciprocalDataDG from './ReciprocalDataDG'

const ReciprocalData = props => {

        return (
            <Fragment>
                <h1>Patients Requiring Reciprocal Data</h1>
                <ReciprocalDataDG />
            </Fragment>
        )
}

export default ReciprocalData