import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'

import RenderExpandableCell from '../../utils/RenderExpandableCell';
import Spinner from '../../layouts/Spinner'

// MUI
import { TextField, Typography } from '@mui/material';
import { DataGrid, 
    GridToolbarContainer,GridToolbarColumnsButton,
    getGridStringOperators,GridToolbarExport
 } from '@mui/x-data-grid';

 const SearchBar = ({setSearchQuery}) => (
    <form style={{
        marginLeft: 5
      }}>
      <TextField
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"
      />
    </form>
  );
  
  const CustomToolbar = (props) => (
    <Fragment>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
      <SearchBar {...props} />
    </Fragment>
  );
const prepTableDate =  (data) => {
    let returnArray = []

    data.map(c => {
        const id = c.id
        // const date = c.startDate ? moment(c.startDate).format('YYYY-MM-DD') : ''
        const plan = c.activity?.length > 0 ? c.activity.map(d => {
                                                              if(d.code && d.start && d.description) return `${ new Date(d.start).toLocaleDateString() }- ${d.code} ${('\n' + d.description)}`
                                                              else if (d.code && d.start) return `${ new Date(d.start).toLocaleDateString() }- ${d.code}`
                                                              else if (d.code && d.description) return `${ new Date(d.start).toLocaleDateString() }- ${d.code} ${('\n' + d.description)}`
                                                              else return ''
                                                            }).join('\n') 
                                                            : 'No Data'
        const provider = c.asserter ? c.asserter : ''
        const resourceType = c.activity?.length > 0 ? new Set(c.activity.map(a=>{return a.resourceType})) : ''

        returnArray.push({id,  provider, plan, resourceType})
    })

    return returnArray

}


const CarePlansDG = ({carePlans}) => {
    let carePlanData

    if(carePlans) carePlanData = prepTableDate(carePlans)
    const [tableData, setTableData] = useState(carePlanData);

    useEffect(() => {
        setTableData(carePlanData)
    }, [carePlans])

    const requestSearch = (searchValue) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = carePlanData.filter((o) => {
          return Object.keys(o).some((k) => {
            return searchRegex.test(o[k]?.toString());
          });
        });
        setTableData(filteredRows);
      };
    //Defines conditions Data Grid columns
    const columnsFormat = [
        {field: 'provider', headerName: 'Provider', width: 200,
              renderCell: (params) => {
                  return params.row.provider
              }},
        // {field: 'date', headerName: 'Date', width: 150,
        //     renderCell: (params) => {
        //         return params.row.date
        //     }},
        {field: 'plan', headerName: 'Plan', width: 2000, 
            renderCell: (params) => {
                // return params.row.plan 
                return (
                  <Fragment>
                    {/* <Typography variant="header">
                      <span key='resourceType'>{params.row.resourceType}</span>
                    </Typography> */}

                      <Typography variant="body2">
                      {params.row.resourceType && params.row.resourceType != '' &&
                        <span key='resourceType'>-{params.row.resourceType}-<br/></span>}
                          { 
                            params.row.plan.split("\n").map((i, key) => {
                                return <span key={key}>{i}<br/></span>;
                            })
                        }
                          </Typography>
                  </Fragment>
                )
            }}
    ]

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

    if(tableData) {
        
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                                setSearchQuery: (searchVal) => requestSearch(searchVal),
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={tableData}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                        getRowHeight={() => 'auto'}
                        
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default CarePlansDG