import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"
import moment from 'moment'

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Tooltip,
    Button,
    IconButton,
} from "@mui/material"

import Alert from '@mui/material/Alert';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonOffIcon from '@mui/icons-material/PersonOff';

// context
import { clearError, ReferralsState, updateReferralAsync } from "./referralsSlice"
import { Box } from '@mui/system';

function ShowAlert(props) {
    return <Alert elevation={6} variant={'filled'} {...props} />
}

const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}


const ReferralConfirmAction = ({data}) => {

    const { error } = useSelector(ReferralsState)
    const dispatch = useDispatch()

    const {date, updateId, name} = data

    const initialState = {
        open: false,
        status: null

    }

    const [dialog, setDialog] = useState(initialState)
    const { open,status} = dialog

    const onclickAccept = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true,status: 'Accepted'}) }
    const onclickReject = () => { 
            dispatch(clearError())
            setDialog({...dialog, open: true,status: 'Rejected'}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = (e) => {
        dispatch(clearError())
        console.log(`Status: ${status} && UpdateId: ${updateId}`)
        dispatch(updateReferralAsync(updateId,status))
        setDialog(initialState)
    }

    // const onChange = e => setDialog({...dialog, [e.target.name]: e.target.value})


    return (
        <Fragment> 
            <Box>     
            <IconButton size='small' variant='outlined' 
                                onClick={onclickAccept}><Tooltip title='Approve'><HowToRegIcon/></Tooltip></IconButton> 
            <IconButton size='small' variant='outlined' 
                                onClick={onclickReject}><Tooltip title='Reject'><PersonOffIcon/></Tooltip></IconButton>    
            </Box>        
            <Dialog open={open} id="referralActionModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'}>
                <DialogTitle id='form-dialog-title'>Confirm Referral Action</DialogTitle>
                <DialogContent>
                <Separator />
                    <DialogContentText>
                        { error && <ShowAlert severity={'error'}>{error}</ShowAlert> }
                    </DialogContentText>

                    <DialogContentText>You are about mark the referral sent by {name} on {moment(date).format("MM/DD/YYYY hh:mm a")} as {status}. </DialogContentText>
                    <Separator />
                    <DialogContentText>Do you wish to continue?</DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Close
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default ReferralConfirmAction