import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import validator from 'validator'

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Select,
    InputLabel,
    FormControl,
    TextField,
    Tooltip,
    Button,
    IconButton
} from "@mui/material"
import Alert from '@mui/material/Alert';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';

// context
import {PeopleState, addNewContactAsync, clearError, setErrorAsync} from './peopleSlice'

function PaperComponent(props) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

function ShowAlert(props) {
    return <Alert elevation={6} variant={'filled'} {...props} />
}

const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}

const AddContact = (props) => {

const { loading, error, person } = useSelector(PeopleState)
const dispatch = useDispatch()

const initialState = {
    open: false,
    value: '',
    use: '',
    system: '',

}

const [dialog, setDialog] = useState(initialState)
let { open,  value, use, system } = dialog


    const handleClickOpen = () => { setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }

    const handleSubmit = (e) => {
        console.log('system: ',system)
        
        if(system === ''){
            dispatch(setErrorAsync('Contact Type is required!'))
            return
        }
        if(use === ''){
            dispatch(setErrorAsync('Contact Use is required!'))
            return
        }
        if(system === 'phone' || system === 'mobile' || system === 'fax' ){
            if(!validator.isMobilePhone(value)){
                dispatch(setErrorAsync('Contact number is invalid!'))
                return
            }
        }
        if(system === 'email'){
            if(!validator.isEmail(value)){
                dispatch(setErrorAsync('Email Address is invalid!'))
                return
            }
        }
        if(system === 'url'){
            if(!validator.isURL(value)){
                dispatch(setErrorAsync('URL is invalid!'))
                return
            }
        }

        dispatch(clearError())
        dispatch(addNewContactAsync(person,{use,value, system},'submit'))
        setDialog(initialState)
    }

    const handleSubmitandContinue = (e) => {
        console.log('system: ',system)
        
        if(system === ''){
            dispatch(setErrorAsync('Contact Type is required!'))
            return
        }
        if(use === ''){
            dispatch(setErrorAsync('Contact Use is required!'))
            return
        }
        if(system === 'phone' || system === 'mobile' || system === 'fax' ){
            if(!validator.isMobilePhone(value)){
                dispatch(setErrorAsync('Contact number is invalid!'))
                return
            }
        }
        if(system === 'email'){
            if(!validator.isEmail(value)){
                dispatch(setErrorAsync('Email Address is invalid!'))
                return
            }
        }
        if(system === 'url'){
            if(!validator.isURL(value)){
                dispatch(setErrorAsync('URL is invalid!'))
                return
            }
        }

        dispatch(clearError())
        dispatch(addNewContactAsync(person,{use,value, system},'next'))
        setDialog({ ...dialog, value: ''})
    }
    
    const onChange = e => setDialog({...dialog, [e.target.name]: e.target.value})


const selectUses = [{key:'home',label:'Home', value:'home'},{key:'mobile',label:'Mobile', value:'mobile'},{key: 'work',label:'Work', value:'work'},{key:'temp',label:'Temp', value:'temp'}]
const selectTypes = [{key:'phone',label:'Phone', value:'phone'},{key: 'email',label:'Email', value:'email'}, {key:'sms',label:'SMS',value:'sms'},{key:'fax',label:'Fax',value:'fax'},{key:'url',label:'URL',value:'url'}]

if (!loading) return (
    <Fragment>
        <IconButton color='inherit' onClick={handleClickOpen}>
        <Tooltip title='Add Contact Point'>
            <AddCircleOutlineSharpIcon fontSize='medium' style={{color: '#000032'}}/>
        </Tooltip>
        </IconButton>                   
 
    <Dialog open={open} id="addAddressModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
    <DialogTitle id='form-dialog-title'>Add Contact Point</DialogTitle>
    <DialogContent>

        <DialogContentText>
            {
                error && <ShowAlert severity={'error'}>{error}</ShowAlert>
            }
        </DialogContentText>

        <Separator />

        <Grid container spacing={2}>
            <Grid item xs={12}>
            <FormControl variant="outlined" style={{minWidth: 200}} required>
                <InputLabel htmlFor="outlined-age-native-simple">Contact Type</InputLabel>
                <Select
                    native
                    required
                    value={system}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Contact Type"
                    inputProps={{
                        name: 'system',
                        id: 'system',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {selectTypes.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>     
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" style={{minWidth: 200}} required>
                <InputLabel htmlFor="outlined-age-native-simple">Contact Use</InputLabel>
                <Select
                    native
                    required
                    value={use}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Contact Use"
                    inputProps={{
                        name: 'use',
                        id: 'use',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {selectUses.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>     
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='value'
                    name='value'
                    label='Contact Value'
                    value={value}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>

        </Grid>

    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
            Cancel
        </Button>
        <Button onClick={handleSubmitandContinue} color={'primary'}>
            Save & Continue
        </Button>
        <Button onClick={handleSubmit} color={'primary'}>
            Save & Close
        </Button>
    </DialogActions>
</Dialog>
</Fragment>
)
else 
        return (<div></div>)
        }
export default AddContact