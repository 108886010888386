import React, { Fragment } from 'react'

const PatientQuery = props => {
        return (
            <Fragment>
                <h1>Patient Query</h1>
            </Fragment>
        )
}

export default PatientQuery