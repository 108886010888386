import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const slice = createSlice({
    name: 'referrals',
    initialState: {
        referralBatches: [],
        loading: true,
        referral: null,
        selectedReferral: null,
        error: null
    },
    reducers: {
        loadReferralBatches : (state, action) => { state.referralBatches = action.payload },
        loadReferral: (state, action) => {
            state.referral = action.payload
            // state.mailItems = (action.payload.data.messages)[state.folder] || []
        },
        setRefferal: (state, action) => { state.selectedReferral = action.payload },
        clearReferral: (state, action) => { 
            state.selectedReferral = null
            state.referral = null
         },
        setLoading: state => { state.loading = true },
        setError: (state, action) => { state.error = action.payload},
        clearError: state => { state.error = null },
    }
})

export const { setLoading, loadReferral, loadReferralBatches, setRefferal, setError, clearError, clearReferral } = slice.actions

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
}

export const getReferralBatchesAsync = () => async dispatch => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Referral`)
        console.log(res.data)
        dispatch(loadReferralBatches(res.data))
    } catch (ex) {
        console.log(ex)
    }
}

export const loadReferralAsync = (id) => async dispatch => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Referral/${id}`)
        console.log(res.data)
        dispatch(loadReferral(res.data))
    } catch (ex) {
        console.log(ex)
    }
}

export const updateReferralAsync = (id,status) => async dispatch => {
    try {
        console.log(id,status)
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/v1/Referral/${id}`,{status})
        console.log(res.data)
        dispatch(getReferralBatchesAsync())
        dispatch(clearReferral())
    } catch (ex) {
        console.log(ex)
    }
}

export const ReferralsState = state => state.referrals

export default slice.reducer