import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from "react-redux"
import UploadBatchForm from './UploadBatchForm'
import DownloadBatchCompositesForm from './DownloadBatchCompositesForm'
import BatchTable from './BatchTable'

import {BatchState, loadBatchesAsync, loadRateOptionsAsync, loadBatchZipsAsync, getBatchZipJobsAsync} from './batchSlice'
import Spinner from '../../layouts/Spinner'
import BatchTemplate from './BatchTemplate'
import { Grid } from "@mui/material";

const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}


const Batch = props => {
    const { loading } = useSelector(BatchState)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadBatchesAsync())
        dispatch(loadRateOptionsAsync())
        dispatch(loadBatchZipsAsync())
        dispatch(getBatchZipJobsAsync())
    }, [])

    return (
        <Fragment>
            <h1>Batches</h1>
            {loading && <Spinner />}
            {!loading && <Fragment>
                <BatchTemplate />
                <Separator />
                <UploadBatchForm /> 
                <Separator />
                <DownloadBatchCompositesForm />
                <BatchTable />
            </Fragment>
            }
        </Fragment>
    )
}

export default Batch