import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'

import RenderExpandableCell from '../../utils/RenderExpandableCell';
import Spinner from '../../layouts/Spinner'

// MUI
import { TextField, Typography } from '@mui/material';
import { DataGrid, 
    GridToolbarContainer,GridToolbarColumnsButton,
    getGridStringOperators,GridToolbarExport
 } from '@mui/x-data-grid';

 const SearchBar = ({setSearchQuery}) => (
    <form style={{
        marginLeft: 5
      }}>
      <TextField
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"
      />
    </form>
  );

  const CustomToolbar = (props) => (
    <Fragment>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
      <SearchBar {...props} />
    </Fragment>
  );

const prepTableData =  (data) => {
    let returnArray = []
    if(data){
        data.map(c => {
            const id = c.id
            const device = c.device ? c.device : ''
            const note = c.note ? c.note : ''
            const quantity = c.quantity ? c.quantity : null
            const date = c.date ? c.date : ''
            const asserter = c.asserter ? c.asserter : ''
            const status = c.status ? c.status : ''
            const bodySite = c.bodySite ? c.bodySite : ''
            

            returnArray.push({id,device, note, quantity, date, asserter, status, bodySite })
        })
    }

    return returnArray

}


const MedicalDevicesDG = ({medicalDevices}) => {
    let medDeviceData
    if(medicalDevices) medDeviceData = prepTableData(medicalDevices)
        
    const [tableData, setTableData] = useState(medDeviceData);

    useEffect(() => {
        setTableData(medDeviceData)
    }, [medicalDevices])

    //Defines conditions Data Grid columns
    const columnsFormat = [
        {field: 'asserter', headerName: 'Asserter', width: 300,
            renderCell: (params) => {
                return params.row.asserter.toUpperCase()
            }},
        {field: 'date', headerName: 'Date', width: 200,
            renderCell: (params) => {
                // return <RenderExpandableCell value={params.row.instruction.toUpperCase()}/>
                return (
                    <Typography variant="body2">
                            <span >{params.row.date.toUpperCase()}</span>
                        </Typography>
                )
            }},
        {field: 'device', headerName: 'Device', width: 400, 
            renderCell: (params) => {
                return (<Typography variant="body2">
                          <span >{params.row.device.toUpperCase()}</span>
                          {params.row.quantity && <span style={{color:'#8a8988'}} ><br/>Quantity: {params.row.quantity}</span>}
                      </Typography>)
            }},
        {field: 'status', headerName: 'Status', width: 150, 
            renderCell: (params) => {
                return params.row.status.toUpperCase()
            }},
        // {field: 'quantity', headerName: 'Quantity', width: 100, 
        //     renderCell: (params) => {
        //         return params.row.quantity
        //     }},
        {field: 'bodySite', headerName: 'Site', width: 400, 
            renderCell: (params) => {
                return params.row.bodySite
            }},
        {field: 'note', headerName: 'Note', width: 400,
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.note.toUpperCase()}/>
            }},
    ]

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

      const requestSearch = (searchValue) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = medDeviceData.filter((o) => {
          return Object.keys(o).some((k) => {
            return searchRegex.test(o[k]?.toString());
          });
        });
        setTableData(filteredRows);
      };
    

    if(tableData) {
        
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: 'date', sort: 'asc' }],
                          },
                        }}
                        componentsProps={{
                            toolbar: {
                                setSearchQuery: (searchVal) => requestSearch(searchVal),
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={tableData}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default MedicalDevicesDG