import React, {  Fragment } from 'react'

//import local component
import ArchivePatientsDG from './ArchivePatientsDG';

const ArchivePatients = props => {
        return (
            <Fragment>
                <h1>Archived Patients</h1>
                <ArchivePatientsDG></ArchivePatientsDG>
            </Fragment>
        )
}

export default ArchivePatients