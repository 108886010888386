import React, { useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

// context
import { FhirResourceState, getCarePlansAsync} from './fhirResourceSlice'
import { PeopleState, getPersonAsync } from "../people/peopleSlice"

//import local component
import ChartHeader from '../people/ChartHeader'
import CarePlansDG from './CarePlansDG'

const CarePlans = () => {
    const { id } = useParams()

    const { carePlans } = useSelector(FhirResourceState)
    const { person } = useSelector(PeopleState)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPersonAsync(id))
        dispatch(getCarePlansAsync(id))
    }, [])

        return (
            <Fragment>
                {person &&  <div style={{marginLeft: 10, marginTop: "30px"}}>
                                <ChartHeader person={person} />
                                <h1>Care Plans</h1>
                                <CarePlansDG carePlans={carePlans}/>
                            </div>}
            </Fragment>
        )
}

export default CarePlans