import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button, 
    Chip
} from "@mui/material"
import Alert from '@mui/material/Alert';

// context
import { ArchiveState, updateArchiveStatusAsync, clearError} from './archiveSlice'
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';

function PaperComponent(props) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

const SetArchiveStatus = ({person}) => {

    const { error } = useSelector(ArchiveState)
    const dispatch = useDispatch()

    const {id, firstName, lastName, isActive} = person

    const initialState = {
        open: false,
        reason: null
    }

    const [dialog, setDialog] = useState(initialState)
    const { open, reason } = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = () => {
        dispatch(updateArchiveStatusAsync({id, isActive:false,reason}))
        setDialog(initialState)
    }
    const onChange = e => setDialog({...dialog, [e.target.name]: e.target.value})

    if(isActive)
        return (
            <Fragment>
                <Box display='flex' justifyContent='flex-mid' >
                    <Tooltip title='Archive Patient' >
                        <Chip
                            variant="filled"
                            sx={{ marginTop: 1 }}
                            color="primary"
                            size='small'
                            label="Archive"
                            onClick={() => {handleClickOpen()}}>
                        </Chip>
                    </Tooltip>
                </Box>

                <Dialog open={open} id="setArchiveStatusModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <DialogTitle id='form-dialog-title'>Set to Archived Status</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { error && <Alert severity={'error'}>{error}</Alert> }
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <DialogContentText>You are about to Archive {firstName} {lastName} </DialogContentText>
                        <DialogContentText>Do you wish to continue?</DialogContentText>
                        </Grid> 
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='reason'
                                name='reason'
                                label='Archive Reason'
                                value={reason}
                                onChange={onChange}
                                variant='outlined'
                            />
                        </Grid>   
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            </Fragment>
        )
}

export default SetArchiveStatus