import { combineReducers } from 'redux'

import people from '../features/people/peopleSlice'
import auth from '../features/auth/authSlice'
import patientQuery from '../features/patientQuery/patientQuerySlice'
import batch from '../features/batches/batchSlice'
import referrals from '../features/referrals/referralsSlice'
import requests from '../features/requests/requestSlice'
import archive from '../features/archived/archiveSlice'
import fhirResource from '../features/fhirResources/fhirResourceSlice'


export default  combineReducers({
    auth,
    archive,
    people,
    patientQuery,
    batch,
    fhirResource,
    referrals,
    requests
})