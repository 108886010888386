import React, { Fragment } from 'react'

//import local component
import RequestsDG from './RequestsDG';

const Requests = props => {

        return (
            <Fragment>
                <h1>Record Requests</h1>
                <RequestsDG></RequestsDG>
            </Fragment>
        )
}

export default Requests